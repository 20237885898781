.news-container {
    width: 80%;
    margin: 1% auto;
    text-align: right;
}

.title {
    margin: 5% auto 0% auto;
    text-align: right;

}

.news-page {
    display: flex;
    flex-direction: column;
}

.news-page h3 {
    color: rgb(182, 182, 182);
    text-align: center;
}

.news-details {
    margin-top: 2%;
    margin-bottom: 15%;
    text-align: right;
    height: 300px;
}
.coming-soon-parent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
a{
    text-decoration: none;
    color: #7050A9;
}
a:hover{
    color: #9676ce;
}

.news-details-page{
    width: 80%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
}

.news-details-page p{
    margin-top: 2%;

}
.news-details-page img {
    display: block;
    width: 80%;
    margin: 0 auto;
}
h6{
    color: #9e9e9e;
}
hr{
    color: #9e9e9e;
}


.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-button {
    margin: 0 5px;
}

.pagination-button.active {
    background-color: #7050a9;
    color: white;
}
.pagination-button:hover{
    background-color: #4b3b69;
}
