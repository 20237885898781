.contact-container{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    background-color: rgba(255, 255, 255, 0.76);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px 0px 0px 10px;
}
.contact-details{
    padding-left: 5%;
    padding-top: 2%;
    text-align: right;


}
.contact-ul{
    list-style: none;
    font-size: smaller;
    color: #2f2f2f;
    padding-top: 5%;

}
.contact-ul a{
    text-decoration: none;
    color: #2f2f2f;

}
.contact-ul a:hover{
    color: #7050A9;
}
.contact-ul li{
    padding: 5px;
}
.contact-ul .icon{
    color: #9e9e9e;
    padding-left: 10px;
}

.map-container {
    height: 100%;
    max-height: 400px; /* You can adjust this value to control the maximum height */
}


