@import url('https://fonts.googleapis.com/css2?family=Cairo&family=Poppins:wght@300&display=swap');

body {
    font-family: 'Cairo', sans-serif;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: 70px;
    padding: 0 30px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    font-family: Cairo, serif;
}
.navbar-nav li{
    padding-left: 60px;
}

.navbar-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;

    /*margin: 0;*/
    /*padding: 0;*/
}

.navbar-menu li {
    margin: 0 10px;
    padding: 0 10px;
}

.navbar-menu a {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
}

.navbar-menu a:hover {
    color: #7050A9;
}
.nav-items{

}

.responsive-image {
    max-width: 20%;
    height: auto;
}
.navbar-container {
    z-index: 1000;
}
/* Add this CSS to your stylesheet */
.custom-nav {
    justify-content: center;
}
.navbar-expand-lg .navbar-nav .nav-link{
    padding-right:50px !important;
}
