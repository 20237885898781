.about-us-container {
    width: 100%;
    padding: 50px;
}

.about-text {
    text-align: right;
    padding: 20px;


}

.about-text h2 {
    font-size: 22px;
    font-weight: bold;
}

.about-text h2:after {
    content: "\2022";
    font-size: 26px;
    color: #9e9e9e;
    padding-left: 2px;
}

.about-text h6 {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.6;
}

.about-text h1 {
    padding-bottom: 10px;
    font-size: 28px;
}

.logo-about {
    margin-top: 5%;
    max-width: 60%;
    width: 40%;
}


.about-text ul {
    margin-right: 0;
    padding-right: 20px;
}

.about-text li {
    direction: rtl;
    font-size: 14px;
    padding-bottom: 5px;
}

.about-us-container h6 {
    color: #3b3b3b !important;
}

p {
    font-size: large;
}

.extra_paragraph {
    margin-bottom: 30px;

}

.aboutus_text {
    margin-bottom: 100px;
}