

.img-fluid{
    border-radius: 10px;
    transition: all .5s ease-in-out;
    height: 600px !important;
    object-fit: cover;
    width: 600px;

}
.img-fluid:hover{
    transform: translateY(-10px);
    box-shadow: -9px 10px 2px 0px rgba(112, 80, 169, 0.40);
}
.text-alignment{
    text-align: right;
}

.text-custom-primary {
margin-bottom: 5%;
    color: #7050A9;
}
.text-right {
    text-align: right;

}
.text-muted{
    line-height: 170%;
}
.about-home{
    margin-top: 8%;
    margin-bottom: 5%;
    text-align: right;
}


.about-home .row {
    align-items: flex-start;
}
.btn-primary{
    background-color: #7050A9 !important;
}

@media only screen and (max-width: 800px) {
   .img-responsive{
       padding-top: 5% !important;
   }
    .img-responsive:hover{
        box-shadow: none ;
    }
}