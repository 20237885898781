.staff-container {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10%;
    text-align: right;
    flex-direction: column;
    min-height: 80vh;
}

.staff-title {
    text-align: center;
    margin-bottom:90px;
}

.staff-image-container {
    width: 150px;
    height: 150px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.staff-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.staff-image-container:hover{
    transform: translateY(-10px);
    box-shadow: -4px 5px 2px 0px rgba(112, 80, 169, 0.40);


}
.staff-card{
    text-align: center;
    padding-bottom: 30px;

}

.col-md-4{
    margin-bottom: 10px !important;
}
iframe{

    padding-top: 5px;
    padding-right: 5px;
}
.fontawesomeicon{
    padding-left: 5px;
}