.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #bea9e5;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-parent{
    min-height: 80vh;
}