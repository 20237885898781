.gallery-container {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10%;
    flex-direction: column;
    min-height: 80vh;
}

.gallery-row {
    text-align: center;
}



.gallery-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: right;
}

.gallery-container h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.gallery-container .row {
    margin: 10px;
}

.gallery-container .col-lg-4 {
    width: 400px;
    height: 200px;
    position: relative;
    overflow: hidden;
    margin: 10px;
    border-radius: 2px;
}

.gallery-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.album-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0; /* Add this line to cover the full width of the parent container */
    bottom: 0; /* Add this line to cover the full height of the parent container */
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.col-lg-4:hover .album-overlay {
    opacity: 1;
}

.album-title {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.preloaded{
    flex-direction: column;
    min-height: 80vh;
}