

@import url('https://fonts.googleapis.com/css2?family=Cairo&family=Poppins:wght@300&display=swap');

*{
    font-family: 'Cairo', sans-serif !important;
}
.carousel .slick-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero-container {
    margin-top: 0;
}
.carousel-item {
    height: 600px;
    transition: transform 0.5s ease-in-out;
}

.carousel-item.active {
    transform: translateX(0);
}

.slide-image {
    height: 100%;
    object-fit: cover;
    filter: brightness(80%);
}

.carousel-caption a{
    color: white;
}
.carousel-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    color: white;
}

.carousel-caption h5 {
    font-size: 24px;
    margin-bottom: 10px;

}


@media (max-width: 767px) {
    .carousel-caption h5 {
        font-size: 18px;
    }
    .carousel-caption.visible h5 {
        display: block;
    }
}

.hero-container{
    height: 70%;
}
