.newscards{
    padding-top: 5%;
    width: 100%;


}
.card:hover{
    transform: translateY(-10px);
    box-shadow: -9px 10px 2px 0px rgba(112, 80, 169, 0.40), 1px 1px 2px 0px rgba(112, 80, 169, 0.40);

}

.card{
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.cards-container-news{
    margin-top: 50px;
}
.btn-primary:hover{
   background-color: #4b3b69 !important;
}

.activities-cards-container{
    overflow: scroll;
    flex-direction: row;
}
.news-cards{
height: 300px;
    background: none;
    overflow: hidden;
}
.news-cards img{
    height: 200px !important;
    object-fit: cover;
}
.card-title{
    /*text-align: center;*/
}

.cardsrow{
    padding-top: 2%;
    padding-bottom: 2%;
}


.section-title{
    padding-top: 2%;
    transition: all .5s ease-in-out;
}

.section-title:hover{
    color: rgb(91, 60, 150);
}

.text-notfound{
    color: #c5c5c5;

}
.icon{
    color: #c5c5c5;
    padding-left: 10px;
}