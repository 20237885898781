@import "~react-image-gallery/styles/css/image-gallery.css";
.galleryalbum-container{
    text-align: right;
    margin-top: 10%;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;
    min-height: 80vh;
}

.preloaded{
    flex-direction: column;
    min-height: 80vh;
}

.preloading{
    flex-direction: column;
    min-height: 80vh;
}